import server from "./axios.config.js";

export const getCropList = function () {
  return server({
    method: "get",
    baseURL: "http://110.87.103.59:18086/",
    url: `farmfriend/crop/getList`
  });
};
// 产量预测
export const cropCalc = function (params) {
  return server({
    method: "post",
    baseURL: "http://110.87.103.59:18086/",
    url: `farmfriend/output/calc`,
    data: params
  });
};
// 获取地块作物列表(未收获)
export const selectLandCropList = function (id) {
  return server({
    method: "get",
    url: 'http://110.87.103.59:18086/farmfriend/landCrop/selectLandCropList',
    params: {
      land_id: id
    }
  })
}

// 根据多个地块和作物类型查询公共品种
export const getLandsCropTypeList = function (cropId, landIds) {
  return server({
    method: "get",
    url: 'http://110.87.103.59:18086/farmfriend/cropType/getLandsCropTypeList',
    params: {
      cropId: cropId,
      landIds: landIds
    }
  })
}

// 根据地块查询公共作物,传一个地块时为该地块去重后的作物
export const getByLandIds = function (landIds) {
  return server({
    method: "get",
    url: 'http://110.87.103.59:18086/farmfriend/crop/getByLandIds',
    params: {
      landIds: landIds
    }
  })
}
