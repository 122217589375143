<template>
  <div class="wrapper">
    <van-nav-bar
      title="记收成"
      left-arrow
      left-text="返回"
      @click-left="onClickLeft"
    />
    <van-form @submit="onSubmit" validate-trigger="onSubmit">
      <!-- 选择地块 -->
      <van-field
        readonly
        clickable
        name="land"
        :value="land"
        label="地块"
        placeholder="选择地块"
        @click="showLandPicker = true"
        :rules="[{ required: true, message: '不能为空' }]"
      />
      <van-popup v-model="showLandPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="landColumns"
          value-key="name"
          @confirm="onLandConfirm"
          @cancel="showLandPicker = false"
          @change="changeLand"
        />
      </van-popup>
      <!-- 作物 -->
      <van-field
        readonly
        clickable
        name="crop"
        :value="crop"
        label="种植作物"
        placeholder="选择作物"
        @click="showCropPicker = true"
        :rules="[{ required: true, message: '不能为空' }]"
      />
      <van-popup v-model="showCropPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="cropColumns"
          value-key="name"
          @confirm="onCropConfirm"
          @cancel="showCropPicker = false"
          @change="changeCrop"
        />
      </van-popup>
      <!-- 选择品种 -->
      <van-field
        readonly
        clickable
        name="breed"
        :value="breed"
        label="选择品种"
        placeholder="选择品种"
        @click="showBreedPicker = true"
        :rules="[{ required: true, message: '不能为空' }]"
      />
      <van-popup v-model="showBreedPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="breedColumns"
          value-key="name"
          @confirm="onBreedConfirm"
          @cancel="showBreedPicker = false"
        />
      </van-popup>
      <!-- 选择种植条件 -->
      <van-field
        readonly
        clickable
        name="condition"
        :value="condition"
        label="选择种植条件"
        placeholder="选择种植条件"
        @click="showConditionPicker = true"
        :rules="[{ required: true, message: '不能为空' }]"
      />
      <van-popup v-model="showConditionPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="conditionColumns"
          value-key="name"
          @confirm="onConditionConfirm"
          @cancel="showConditionPicker = false"
        />
      </van-popup>
      <!-- 种植面积 -->
      <van-field
        v-model="cultivatedArea"
        label="种植面积"
        name="cultivatedArea"
        placeholder="输入种植面积"
        center
        :rules="[{ required: true, message: '不能为空' }]"
        @input="changeReset"
      >
        <template #extra>
          <span>亩</span>
        </template>
      </van-field>
      <!-- 产量 -->
      <van-field
        v-model="cropYield"
        label="本次卖出"
        name="cropYield"
        placeholder="请输入"
        center
        :rules="[{ required: true, message: '不能为空' }]"
        @input="changeReset"
      >
        <template #extra>
          <span>kg</span>
        </template>
      </van-field>
      <!-- 单价 -->
      <van-field
        v-model="unitPrice"
        label="单价"
        name="unitPrice"
        placeholder="请输入"
        center
        :rules="[{ required: true, message: '不能为空' }]"
        @input="changeReset"
      >
        <template #extra>
          <span>元/kg</span>
        </template>
      </van-field>
      <van-field
        v-model="note"
        rows="2"
        autosize
        label="备注"
        type="textarea"
        placeholder="请输入备注"
        show-word-limit
      />
      <!-- 提交按钮 -->
      <div style="margin: 16px">
        <van-button
          round
          block
          class="comput-button"
          native-type="button"
          @click="computing"
        >
          计算
        </van-button>
      </div>
      <div class="computed-part" v-show="showComputedPart">
        <div class="my-cell">总收入：{{ totalPrice }}元</div>
        <div class="my-cell">亩收入：{{ pricePerArea }}元</div>
        <div style="margin: 16px; text-align: center">
          <van-button class="comput-button" round native-type="submit" size="small">
            记 录
          </van-button>
        </div>
      </div>
    </van-form>
  </div>
</template>
<script>
import {
  NavBar,
  Form,
  Field,
  Button,
  Popup,
  Picker,
  Toast,
  Dialog
} from "vant";
import { getLandsCropTypeList, getByLandIds } from "@/service/cropService.js";
import { saveHarvest, getLandCultivationCondition } from "@/service/harvest.js";
import { selectList } from "@/service/Addfarmland.js";
export default {
  name: "RecordHarvest",
  data() {
    return {
      themeColor: "#001f90",
      land: "",
      landColumns: [],
      showLandPicker: false,
      crop: "",
      cropColumns: [],
      showCropPicker: false,
      breed: "",
      breedColumns: [],
      showBreedPicker: false,
      condition: "",
      conditionColumns: [],
      showConditionPicker: false,
      cultivatedArea: "",
      cropYield: "",
      unitPrice: "",
      totalPrice: "",
      pricePerArea: "",
      showComputedPart: false,
      note: ""
    };
  },
  components: {
    VanNavBar: NavBar,
    VanForm: Form,
    VanField: Field,
    VanButton: Button,
    VanPopup: Popup,
    VanPicker: Picker
  },
  created() {
    // getCropList().then((res) => {
    //   this.cropColumns = res.data;
    // });
    selectList().then((res) => {
      if (res.code === 200) {
        if (res.data.length === 0) {
          Dialog.confirm({
            title: "提示",
            message: "请添加地块，种植作物后再进行此项操作",
            confirmButtonText: "去画地",
            cancelButtonText: "再看看"
          })
            .then(() => {
              this.$router.push("/addfarmland");
            })
            .catch(() => {
              // on cancel
            });
        }
        this.landColumns = res.data;
      }
    });
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onLandConfirm(value) {
      this.land = value.name;
      this.submitLand = value.id;
      this.showLandPicker = false;
      if (value.id) {
        getByLandIds(value.id).then((res) => {
          if (res.code === 200) {
            if (res.data.length === 0) {
              Dialog({
                message: "此地块未种植作物，请先添加种植作物",
                confirmButtonText: "知道了"
              });
            }
            this.cropColumns = res.data;
          }
        });
      }
    },
    onCropConfirm(value) {
      this.crop = value.name;
      this.submitCrop = value.id;
      this.showCropPicker = false;

      // 级联赋值
      getLandsCropTypeList(value.id, this.submitLand).then((res) => {
        if (res.code === 200) {
          this.breedColumns = res.data;
        }
      });
      // this.breedColumns = value.cropTypeList;
      getLandCultivationCondition(value.id, this.submitLand).then((res) => {
        if (res.code === 200) {
          this.conditionColumns = res.data;
        }
      });
    },
    onBreedConfirm(value) {
      this.breed = value.name;
      this.submitBreed = value.id;
      this.showBreedPicker = false;
    },
    onConditionConfirm(value) {
      this.condition = value.name;
      this.submitCondition = value.id;
      this.showConditionPicker = false;
    },
    changeCrop() {
      this.breed = "";
      this.submitBreed = "";
      this.breedColumns = [];
    },
    changeLand() {
      this.crop = "";
      this.submitCrop = "";
      this.cropColumns = [];
      this.breed = "";
      this.submitBreed = "";
      this.breedColumns = [];
      this.condition = "";
      this.submitCondition = "";
      this.conditionColumns = [];
    },
    computing() {
      if (this.cropYield && this.unitPrice && this.cultivatedArea) {
        this.totalPrice = (this.cropYield * this.unitPrice).toFixed(2);
        this.pricePerArea = (this.totalPrice / this.cultivatedArea).toFixed(2);
        this.showComputedPart = true;
      }
    },
    onSubmit(values) {
      const data = {
        crop_id: this.submitCrop,
        crop_type_id: this.submitBreed,
        cultivation_condition: this.submitCondition,
        cultivation_condition_name: this.condition,
        area: values.cultivatedArea,
        quantity: values.cropYield,
        price: values.unitPrice,
        totalPrice: this.totalPrice,
        pricePerArea: this.pricePerArea,
        land_id: this.submitLand,
        note: this.note
      };
      Toast.loading({
        message: "记录中...",
        forbidClick: true
      });
      saveHarvest(data).then((res) => {
        if (res.code === 200) {
          Toast.success("记录成功");
          // location.reload();
          this.$router.push("/mine/harvestRecords");
        } else {
          Toast.fail("记录失败");
        }
      });
    },
    changeReset() {
      this.totalPrice = "";
      this.pricePerArea = "";
      this.showComputedPart = false;
    }
  }
};
</script>
<style lang="scss" scoped>
$color: #001f90;
.wrapper {
  .half {
    text-align: center;
  }
  .feedback-button {
    margin-top: 24px;
  }
  .my-cell {
    text-align: center;
    padding: 8px;
  }
  .comput-button {
    background: $color;
    color: #fff;
  }
}
</style>
