import server from "./axios.config.js";

export const saveHarvest = function (data) {
  return server({
    method: "post",
    baseURL: "http://110.87.103.59:18086/",
    url: `farmfriend/harvest/save`,
    data: data
  });
};

export const getHarvestList = function () {
  return server({
    method: "get",
    baseURL: "http://110.87.103.59:18086/",
    url: `farmfriend/harvest/getList`
  });
};

export const deleteHarvest = function (id) {
  return server({
    method: "post",
    baseURL: "http://110.87.103.59:18086/",
    url: `farmfriend/harvest/delete`,
    data: {
      id
    }
  });
};
export const getLandCultivationCondition = function (cropId, landId) {
  return server({
    method: "get",
    baseURL: "http://110.87.103.59:18086/farmfriend/crop/getLandCultivationCondition",
    params: {
      crop_id: cropId,
      land_id: landId
    }
  });
};
